<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="6">
          <h5>{{ $t('errorLog.menuItem') }}</h5>
        </v-col>
        <v-col cols="6" lg="4" offset-lg="2" class="text-right">
          <v-btn small @click="downloadFile">
            <v-icon small class="mr-2"> mdi-file-excel-outline </v-icon>
            {{ $t('errorLog.export') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="errors"
        :items-per-page="pageSize"
        :loading="dataLoading"
        :loading-text="$t('dataLoading')"
        :fixed-header="true"
        :server-items-length="emailsCount"
        :footer-props="{
          disableItemsPerPage: true,
          itemsPerPageOptions: [pageSize]
        }"
      >
        <template v-slot:item.errorDate="{ item }">
          {{ $d(new Date(item.errorDate), 'datetime') }}
        </template>

        <template v-slot:item.errorType="{ item }">
          {{ $t(`errorLog.errorTypes.${item.errorType}`) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import errorLogService from '@/services/ErrorLogService.js';

export default {
  data: () => ({
    errors: [],
    pageSize: 20
  }),

  watch: {},

  computed: {
    headers() {
      return [
        {
          text: this.$t('errorLog.date'),
          sortable: true,
          filterable: true,
          value: 'errorDate',
          width: 200
        },
        {
          text: this.$t('errorLog.errorType'),
          sortable: true,
          filterable: true,
          value: 'errorType',
          width: 350
        },
        {
          text: this.$t('errorLog.username'),
          sortable: true,
          filterable: true,
          value: 'username',
          width: 250
        },
        {
          text: this.$t('errorLog.errorDescription'),
          sortable: true,
          value: 'description',
          filterable: false
        }
      ];
    }
  },

  async mounted() {
    this.errors = await this.fetchErrors();
  },

  methods: {
    ...mapActions({
      fetchErrors: 'errorLog/fetchErrors'
    }),

    async downloadFile() {
      const data = await errorLogService.download();
      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;
      anchor.download = this.getExportFileName();
      anchor.click();

      window.URL.revokeObjectURL(fileUrl);
    },

    getExportFileName() {
      var dateString = new Date().toISOString().slice(0, 10).replace(/-/g, '');
      return `${dateString}_errors.xlsx`;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table tbody tr td:hover {
  cursor: pointer;
}
</style>
