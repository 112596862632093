<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row class="align-center">
        <v-col cols="6">
          <h5>{{ $tc('email', 2) }}</h5>
        </v-col>
        <v-col cols="6" lg="4" offset-lg="2">
          <v-text-field
            v-model="keyword"
            solo
            flat
            hide-details
            :label="$t('searchKeywordPlaceholder')"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="emails"
        :search="keyword"
        :items-per-page="pageSize"
        :loading="dataLoading"
        :options.sync="options"
        :loading-text="$t('dataLoading')"
        :fixed-header="true"
        :server-items-length="emailsCount"
        :footer-props="{
          disableItemsPerPage: true,
          itemsPerPageOptions: [pageSize]
        }"
        @click:row="rowClicked"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'debounce';

export default {
  data: () => ({
    keyword: '',
    dataLoading: false,
    options: {},
    pageSize: 12
  }),

  watch: {
    keyword(value) {
      this.options.page = 1;
      this.loadEmails(this, value);
    },
    options: {
      handler(value) {
        this.loadEmails(
          this,
          this.keyword,
          value.page - 1,
          value.itemsPerpage,
          value.sortBy[0],
          value.sortDesc[0]
        );
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      emailsStore: 'emails/getEmails',
      emailsCount: 'emails/getEmailsCount'
    }),

    emails() {
      return this.emailsStore.map((x) => {
        if (Number.isInteger(x.status)) {
          x.status = this.$t('emailDetails.statusList.' + x.status);
        }

        return x;
      });
    },

    headers() {
      return [
        {
          text: this.$t('emailDetails.sender'),
          sortable: true,
          filterable: true,
          value: 'sender'
        },
        {
          text: this.$t('emailDetails.recipient'),
          sortable: true,
          filterable: true,
          value: 'recipient'
        },
        {
          text: this.$t('emailDetails.subject'),
          sortable: true,
          filterable: true,
          value: 'subject'
        },
        {
          text: this.$t('emailDetails.status'),
          sortable: true,
          value: 'status',
          filterable: false
        },
        {
          text: this.$t('emailDetails.sendingDateTime'),
          sortable: true,
          filterable: false,
          value: 'sendingDateTime'
        }
      ];
    }
  },

  methods: {
    ...mapActions({
      fetchEmails: 'emails/fetchEmails'
    }),

    rowClicked(item) {
      this.$router.push({
        name: 'EmailDetails',
        params: { id: item.id }
      });
    },

    loadEmails: debounce(
      async (self, keyword, pageIndex, pageSize, sort, desc) => {
        self.dataLoading = true;
        await self.fetchEmails({
          keyword: keyword,
          pageIndex: pageIndex,
          pageSize: pageSize,
          sort,
          desc
        });
        self.dataLoading = false;
      },
      300
    )
  }
};
</script>

<style lang="scss" scoped>
.v-data-table tbody tr td:hover {
  cursor: pointer;
}
</style>
