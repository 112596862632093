var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"segmented",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('errorLog.menuItem')))])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6","lg":"4","offset-lg":"2"}},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.downloadFile}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-excel-outline ")]),_vm._v(" "+_vm._s(_vm.$t('errorLog.export'))+" ")],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.errors,"items-per-page":_vm.pageSize,"loading":_vm.dataLoading,"loading-text":_vm.$t('dataLoading'),"fixed-header":true,"server-items-length":_vm.emailsCount,"footer-props":{
        disableItemsPerPage: true,
        itemsPerPageOptions: [_vm.pageSize]
      }},scopedSlots:_vm._u([{key:"item.errorDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(new Date(item.errorDate), 'datetime'))+" ")]}},{key:"item.errorType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("errorLog.errorTypes." + (item.errorType))))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }